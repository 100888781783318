<template>
  <div class="list-promo">
    <KTPortlet title="Danh sách khuyến mại" headSize="md">
      <template v-slot:toolbar>
        <router-link :to="{ name: 'promo-create' }">
          <i class="flaticon-add mr-2"></i>
          <span>Tạo mới</span>
        </router-link>
      </template>

      <template v-slot:body>
        <div class="row">
          <div class="col-6">
            <SearchInput
              pHolder="Nhập để tìm kiếm..."
              @search="onSearch"
            ></SearchInput>
          </div>
          <div class="col-6 search-outlet">
            <OutletSelect
              @change="onSelectOutlet"
              :hidenTitle="true"
            ></OutletSelect>
          </div>
        </div>

        <div class="table-custom mt-4">
          <b-table
            responsive="sm"
            v-if="listPromos.length"
            striped
            :items="listPromos"
            :fields="fields"
            head-variant="light"
            :sticky-header="true"
            aria-controls="promo-table"
            :total-rows="totalItem"
            :per-page="itemPage"
          >
            <template v-slot:cell(name)="data">
              <div v-b-toggle="data.value" @click="data.toggleDetails">
                <span class="icon mr-2 icon-arrow"
                  ><i
                    :class="
                      data.detailsShowing ? 'flaticon2-back' : 'flaticon2-down'
                    "
                  >
                  </i>
                </span>
                <span>{{ data.value }}</span>
              </div>
            </template>
            <template v-slot:cell(status)="data">
              <b-dropdown
                right
                variant="success"
                no-caret
                id="dropdown-right"
                text="Kích hoạt"
                v-if="data.value === 1"
                size="sm"
                class="btn-status"
              >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(data, status.Actived)"
                  >Kích hoạt</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(data, status.NotActived)"
                  >Chưa kích hoạt</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                right
                variant="danger"
                no-caret
                id="dropdown-right"
                text="Chưa KH"
                size="sm"
                v-else-if="data.value === 2"
                class="btn-status"
              >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(data, status.Actived)"
                  >Kích hoạt</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="changeActived(data, status.NotActived)"
                  >Chưa kích hoạt</b-dropdown-item
                >
              </b-dropdown>
            </template>
            <template v-slot:cell(typePromo)="data">
              <span>
                {{ data.value === 0 ? "Phần trăm" : "Tiền" }}
              </span>
            </template>
            <template v-slot:cell(_id)="data">
              <div class="text-right edit-promo">
                <b-dropdown
                  right
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="b-none"
                >
                  <template v-slot:button-content>
                    <i class="flaticon-more"></i>
                  </template>
                  <b-dropdown-item href="#">
                    <router-link
                      :to="{
                        path: '/edit',
                        query: { item: data.item }
                      }"
                    >
                      <div class="d-flex align-items-center">
                        <i class="flaticon-edit-1 mr-2"></i>
                        <span>Chỉnh sửa</span>
                      </div>
                    </router-link>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template v-slot:row-details="row">
              <b-card class="card-content">
                <p class="card-text">
                  Mô tả: <span class="content">{{ row.item.description }}</span>
                </p>
                <div class="card-text d-flex">
                  <div>Tiệm áp dụng :</div>
                  <div class="pl-2">
                    <p
                      class="content"
                      v-for="(item, index) in getOutlet(row.item.outletId)"
                      :key="index"
                    >
                      - {{ item }}
                    </p>
                  </div>
                </div>
              </b-card>
            </template>
          </b-table>

          <div v-else class="d-flex justify-content-center">
            <section v-if="loading" class="loading align-items-center">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
            </section>
            <div class="loading align-items-center" v-else>
              <div class="text-center">
                <h1><i class="flaticon2-open-box"></i></h1>
                <h5>Không tìm thấy dữ liệu thích hợp</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-12 d-flex justify-content-center"
          v-if="totalItem > itemPage"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItem"
            :per-page="itemPage"
            id="promo-table"
          ></b-pagination>
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<style lang="scss">
@import "./Promo.scss";
.loading {
  height: calc(100vh - 505px);
}
</style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import SearchInput from "@/components/SearchInput.vue";
import { mapGetters } from "vuex";
import { Status } from "./../../constans";
import Loader from "@/common/loader";

export default {
  components: {
    OutletSelect,
    KTPortlet,
    SearchInput
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Tên KM",
          stickyColumn: true,
          variant: "light"
        },
        {
          key: "status",
          label: "Trạng thái"
        },
        {
          key: "typePromo",
          label: "Hình thức khuyến mại"
        },
        {
          key: "value",
          label: "Giá trị"
        },
        {
          key: "_id",
          label: ""
        }
      ],
      query: {},
      loadMore: true,
      outletIdCurrent: "",
      bottom: false,
      listPromos: [],
      status: Status,
      currentPage: 1,
      totalItem: 0,
      itemPage: 10,
      loading: false
    };
  },
  computed: mapGetters(["allSessions", "allOutlets"]),

  watch: {
    currentPage(val) {
      this.getData();
    }
  },

  created() {
    this.$store.dispatch("titleBreadcrumb", "Quản lý khuyến mại");
    this.resetListOutlets();
    this.getListOutlet();
  },
  methods: {
    onSelectOutlet(event) {
      Loader.fire();
      this.query.outletId = event;
      this.outletIdCurrent = event;
      this.query.currentPage = 1;
      this.$store
        .dispatch("getListPromo", this.query)
        .then(() => {
          this.listPromos = this.$store.state.promo.promo.data;
          this.totalItem = this.$store.state.promo.promo.total;
        })
        .finally(Loader.close);
    },
    getData() {
      this.listPromos = [];
      this.loading = true;
      this.query.currentPage = this.currentPage;
      this.$store
        .dispatch("getListPromo", this.query)
        .then(() => {
          this.listPromos = this.$store.state.promo.promo.data;
          this.totalItem = this.$store.state.promo.promo.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onViewUserReport(id) {
      this.$router.push({ name: "session-detail", params: { id } });
    },
    onSearch($event) {
      Loader.fire();
      this.query = {};
      this.query.searchText = $event ? $event : "";
      if ($event === "") {
        this.query = {};
      }
      this.query.outletId = this.outletIdCurrent;
      this.$store
        .dispatch("getListPromo", this.query)
        .then(() => {
          this.listPromos = this.$store.state.promo.promo.data;
          this.totalItem = this.$store.state.promo.promo.total;
        })
        .finally(Loader.close);
    },
    getSessionList() {
      this.$store.dispatch("setSessions", this.query);
    },
    productTotal(items) {
      if (!items) return 0;
      return items.reduce((a, b) => (a += b.product.price), 0);
    },

    changeActived(data, actived) {
      Loader.fire();
      const idItem = data.item._id;
      const query = {
        id: idItem,
        params: {
          description: data.item.description,
          name: data.item.name,
          outletId: data.item.outletId,
          status: actived,
          typePromo: data.item.typePromo,
          value: data.item.value
        }
      };
      this.$store
        .dispatch("updatePromo", query)
        .then(() => {
          this.listPromos = this.$store.state.promo.promo.data;
          this.totalItem = this.$store.state.promo.promo.total;
        })
        .finally(Loader.close);
    },

    resetListOutlets() {
      this.$store.dispatch("resetListOutlets");
    },

    getListOutlet() {
      Loader.fire();
      this.$store.dispatch("setOutlets", this.query).finally(Loader.close);
    },

    getOutlet(listId) {
      let listOutet = [];
      listId.forEach(id => {
        const index = this.allOutlets.findIndex(item => item._id === id);
        if (index !== -1) {
          listOutet.push(this.allOutlets[index].name);
        }
      });
      return listOutet;
    }
  }
};
</script>
